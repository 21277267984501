<template>
	<div class="my-8">
		<h2>Premise based dialer enabled.</h2>
		<div>Processed list files are available for download and re-upload into your premise based equipment</div>
	</div>
</template>

<script>
	import { defineComponent } from 'vue';
	import PremService from "@/modules/dialers/prem/PremService";

	export default defineComponent({
		name: 'PremSettings',

		props: ['workspace'],

		components: {},

		data: function () {
			return {
				premService: new PremService()
			};
		},

		mounted() {

		},

		beforeUnmount() {},

		methods: {
			async save() {
				try {
					let result = await this.premService.connect(this.workspace.id);
					return result.data;
				}catch(err){
					this.error = true;
					console.log(err);
					this.$store.commit('error','Error connecting Prem workspace');
				}
			},
		},

		computed: {},

		watch: {

		},
	});
</script>

<style scoped lang="scss">

</style>
