<template>
	<div class="left-nav column-format pt-3">
		<div class="column-format" v-if="selectedApp === 'Core'">
			<nav-link icon="campaign" label="Campaigns" route="/campaigns"></nav-link>
			<nav-link icon="dynamic_feed" label="Bulk upload" route="/bulk"></nav-link>
			<nav-link icon="list_alt" label="Lists" route="/lists"></nav-link>
			<nav-link icon="fingerprint" label="Identities" route="/identities"></nav-link>
			<nav-link icon="phone_in_talk" label="Pool numbers" route="/pools"></nav-link>
			<nav-link icon="groups" label="Leads" route="/leads"></nav-link>
			<nav-link icon="security" label="UC Protect" route="/protect"></nav-link>
			<nav-link icon="search_check" label="Number utility" route="/utilities"></nav-link>
		</div>
		<div v-if="selectedApp === 'Aegis One'">
			<nav-link icon="shield" label="Dashboard" route="/aegis-one"></nav-link>
			<nav-link icon="phone" label="Calls" route="/aegis-calls"></nav-link>
			<nav-link icon="family_history" label="Workflows" route="/workflows"></nav-link>
			<nav-link icon="database" label="Record sets" route="/record-sets"></nav-link>
		</div>
		<div v-if="selectedApp === 'Analytics'">
			<nav-link icon="monitoring" label="Dashboard" route="/dashboard"></nav-link>
			<nav-link icon="table_chart" label="Usage reports" route="/usage"></nav-link>
		</div>
		<div v-if="selectedApp === 'Admin'">
			<nav-link icon="workspaces" label="Workspaces" route="/workspaces"></nav-link>
			<nav-link icon="supervisor_account" label="Team" route="/team"></nav-link>
			<nav-link icon="verified_user" label="Watchtower" route="/watch-tower"></nav-link>
			<nav-link icon="request_quote" label="Billing profile" route="/billing-profile"></nav-link>
			<nav-link icon="webhook" label="Webhooks" route="/webhooks"></nav-link>
		</div>
	</div>
</template>

<script>
	import { defineComponent } from 'vue';
	import NavLink from '@/modules/nav/NavLink';
	export default defineComponent({
		name: 'LeftNav',

		props: [],

		components: { NavLink },

		data: function () {
			return {};
		},

		mounted() {},

		beforeUnmount() {},

		methods: {},

		watch: {
			selectedApp: function () {
				this.$router.push(this.defaultRoute);
			},
		},

		computed: {
			defaultRoute: function () {
				if (this.selectedApp === 'Core') {
					return '/campaigns';
				} else if (this.selectedApp === 'Aegis One') {
					return '/aegis-one';
				} else if (this.selectedApp === 'Admin') {
					return '/workspaces';
				} else {
					return '/dashboard';
				}
			},

			leftNavExpanded: function () {
				return this.$store.state.leftNavExpanded;
			},

			selectedApp: function () {
				return this.$store.state.selectedApp;
			},
		},
	});
</script>

<style scoped lang="scss">
	.left-nav {
		background-color: rgb(var(--v-theme-secondary));
		width: 100%;
		height: 100%;
		border-right: 1px solid rgb(var(--v-theme-secondary));
	}
</style>
