<template>
	<div id="workflow-editor" class="fill-all" v-if="isReady">
		<div class="header-area pl-1 pr-3 row-format align-center">
			<div class="row-format gap-2 align-center">
				<v-text-field
					style="width: 198px"
					v-model="workflow.name"
					placeholder="Workflow name"
					hide-details
					persistent-placeholder
					variant="outlined"
					density="compact"
				></v-text-field>
				<v-select
					style="width: 150px"
					:items="['Active', 'Disabled']"
					v-model="workflow.status"
					hide-details
					persistent-placeholder
					variant="outlined"
					density="compact"
				></v-select>
				<!--p-icon class="material-symbols-outlined pointer" @click="$refs.flow.findHome()">home</p-icon-->
				<p-icon class="material-symbols-outlined pointer" @click="$refs.flow.zoomIn()">zoom_in</p-icon>
				<p-icon class="material-symbols-outlined pointer" @click="$refs.flow.zoomOut()">zoom_out</p-icon>
			</div>
			<div class="ml-auto row-format gap-2 align-center">
				<div v-if="workflow.publishedTimestamp" class="font-12 font-gray_70 mr-3">
					Published: {{ formatForTimeAgo(workflow.publishedTimestamp) }}
				</div>
				<v-btn @click="testWorkflow()" icon="play_circle" size="small" style="max-height: 36px; border-radius: 8px" color="secondary" variant="outlined" v-tippy="{content:'Run test call'}"></v-btn>
				<v-btn class="primary-action" width="96" @click="save(false)">
					Save
				</v-btn>
				<v-btn class="super-action" :disabled="!workflow.draft" @click="confirmPublish"> Publish </v-btn>
				<v-icon @click="save(true)">close</v-icon>
			</div>
		</div>
		<div style="width: 100%; height: calc(100% - 56px)">
			<workflow ref="flow" :workflow="workflow.flow" @node-added="addNode($event)" @node-removed="deleteNode($event)"></workflow>
		</div>
	</div>
</template>

<script>
	import Workflow from '@/modules/workflow/Workflow';
	import WorkflowService from '@/modules/workflow/WorkflowService';
	import ConfirmModal from '@/components/ConfirmModal';
	import {DateTime} from 'luxon';
	import {defineComponent} from "vue";
	import NodeConfigModal from "@/modules/workflow/config/NodeConfigModal";
	import PIcon from "@/components/PIcon";
	import WorkflowTestModal from "@/modules/workflow/WorkflowTestModal";

	export default defineComponent({
		name: 'WorkflowEditor',

		props: ['id'],

		components: { Workflow, PIcon},

		data: function () {
			return {
				workflow: null,
				isReady: false,
				workflowService: new WorkflowService(),
			};
		},

		mounted() {
			this.$store.state.eventBus.$on('workflow-node', this.handleWorkflowNodeClicked);
			this.loadWorkflow();
		},

		beforeUnmount() {
			this.$store.state.eventBus.$off('workflow-node', this.handleWorkflowNodeClicked);
		},

		methods: {
			loadWorkflow: function () {
				this.workflowService.getWorkflow(this.workspaceId,this.id).then((res) => {
					this.workflow = res.data;
					this.isReady = true;
				});
			},

			testWorkflow: function(){
				this.$store.state.globalModalController.openModal(WorkflowTestModal,{id: this.id}, true, false, true, false, 700);
			},

			handleWorkflowNodeClicked: function(nodeId){
				let ix = this.workflow.nodes.findIndex(n => n.id === nodeId);
				let node = this.workflow.nodes[ix];
				let width = 500;

				let binding = {
					node:node
				}

				if(node.type === 'Decision'){
					width = 900;
				}

				this.$store.state.globalModalController.openModal(NodeConfigModal,binding, false, false, true, true, width).then((res) => {
					this.workflow.nodes.splice(ix,1,res);
					this.$df.value.updateNodeDataFromId(nodeId,{ label: res.label });
					this.$store.state.eventBus.$emit('df-node-updated',res);
				})
			},

			addNode: function(node){
				let newNode = {
					id: node.id,
					type: node.type
				}

				if(node.type === 'CallerId'){
					newNode.campaignId = null;
				}else if(node.type === 'Decision'){
					newNode.conditionType = 'AND';
					newNode.conditions = [];
				}else if(node.type === 'DoNotCall'){
					newNode.ftcSanId = null;
				}else if(node.type === 'RecordSet'){
					newNode.recordSetId = null;
					newNode.zipOverride = 'NEVER';
					newNode.zipAttribute = null;
				}else if(node.type === 'Velocity'){
					newNode.maxCalls = 7;
					newNode.inDays = 7;
					newNode.suppressForDays = 1;
				}else if(node.type === 'TimeOfDay'){
					newNode.sunday = [];
					newNode.monday = [{start:480, end:1260}];
					newNode.tuesday = [{start:480, end:1260}];
					newNode.wednesday = [{start:480, end:1260}];
					newNode.thursday = [{start:480, end:1260}];
					newNode.friday = [{start:480, end:1260}];
					newNode.saturday = [];
					newNode.overrides = new Map();
				}

				this.workflow.nodes.push(newNode);
			},

			deleteNode: function(id){
				let ix = this.workflow.nodes.findIndex(n => n.id === id);
				if(ix > -1){
					this.workflow.nodes.splice(ix,1);
				}
			},

			doSave: function () {
				return new Promise((resolve, reject) => {
					this.workflow.flow = this.$refs.flow.doExport();
					this.workflowService
						.updateWorkflow(this.workspaceId, this.id, this.workflow)
						.then((res) => {
							resolve(res);
						})
						.catch((err) => reject(err));
				});
			},

			save: function (close = false) {
				this.$store.commit('startLoading');
				this.doSave()
					.then((res) => {
						this.workflow = res.data;
						if (close) {
							this.$emit('result', this.workflow);
						}
					})
					.finally(() => this.$store.commit('stopLoading'));
			},

			confirmPublish: function () {
				let binding = {
					headingText: 'Publish?',
					bodyText: 'Are you sure you want to publish this version of the workflow?',
				};
				this.$store.state.globalModalController.openModal(ConfirmModal, binding).then((res) => {
					if (res) {
						this.saveAndPublish();
					}
				});
			},

			saveAndPublish: function () {
				this.$store.commit('startLoading');
				this.doSave()
					.then((res) => {
						this.workflow = res.data;
						this.workflowService.publishWorkflow(this.workspaceId,this.id).then((res) => {
							this.workflow.draft = res.data.draft;
							this.workflow.publishedTimestamp = res.data.publishedTimestamp;
						});
					})
					.finally(() => this.$store.commit('stopLoading'));
			},

			delete: function () {
				let binding = {
					headingText: 'Confirm',
					bodyText: 'Are you sure you want to delete this workflow?  This cannot be undone.',
					confirmText: 'DELETE',
					confirmHint: 'Type the word DELETE to confirm',
				};
				this.$store.state.globalModalController.openModal(ConfirmModal, binding).then((res) => {
					if (res) {
						this.workflowService.deleteWorkflow(this.workspaceId,this.id).then(() => {
							this.workflow.deleted = true;
							this.$emit('result', this.workflow);
						});
					}
				});
			},

			formatForTimeAgo: function (date) {
				let yesterday = DateTime.now().minus({ days: 1 }).startOf('day');
				let today = DateTime.now().startOf('day');
				let tomorrow = DateTime.now().plus({ days: 1 }).startOf('day');
				let compareDate = DateTime.fromISO(date).startOf('day');

				if (compareDate.equals(today)) {
					return 'Today at ' + DateTime.fromISO(date).toLocaleString(DateTime.TIME_SIMPLE);
				} else if (compareDate.equals(tomorrow)) {
					return 'Tomorrow at ' + DateTime.fromISO(date).toLocaleString(DateTime.TIME_SIMPLE);
				} else if (compareDate.equals(yesterday)) {
					return 'Yesterday at ' + DateTime.fromISO(date).toLocaleString(DateTime.TIME_SIMPLE);
				} else {
					return DateTime.fromISO(date).toLocaleString(DateTime.DATETIME_MED);
				}
			},
		},

		computed: {
			workspaceId: function () {
				return this.$store.state.selectedWorkspace;
			},
		},
	});
</script>

<style scoped lang="scss">
	#workflow-editor {
		background-color: rgb(var(--v-theme-white));
		width: 100vw;
		height: 100vh;

		.header-area {
			height: 56px;
			width: 100%;
			background-color: rgb(var(--v-theme-white));
			border-bottom: 1px solid rgb(var(--v-theme-gray_50));
			// Needed for sticky positioning
			position: sticky;
			position: -webkit-sticky;
			top: 0;
			z-index: 203;
		}
	}
</style>
