<template>
	<v-container>
		<v-row align="center" justify="center">
			<v-col cols="12">
				<div class="row-format centered">
					<div class="auth-logo"><img alt="logo" src="/images/logo.png" /></div>
				</div>
			</v-col>
			<v-col cols="12" sm="6" md="6">
				<v-card class="elevation-12">
					<v-toolbar-title>
						<span>Authenticating session.  Please wait.</span>
					</v-toolbar-title>
				</v-card>
			</v-col>
		</v-row>
	</v-container>
</template>

<script>
	import AuthService from '@/modules/auth/AuthService';

	export default {
		name: 'Token',

		components: {  },

		props: ['token'],

		data() {
			return {
				password: '',
				confirmPassword: '',
				phone: '',
				phoneValid: false,
				showError: false,

				passwordRules: AuthService.getPasswordRules(),

				confirmPasswordRules: [
					(v) => !!v || 'Confirm password is required',
					(v) => v === this.confirmPassword || 'Passwords do not match',
				],

				valid: false,
			};
		},

		async mounted() {
			await this.verifyToken();
		},

		methods: {
			async verifyToken() {
				try {
					let res = await AuthService.authenticationToken(this.token);
					console.log(res);
					await this.processAuthenticationResponse(res.authorizedUser);
				} catch (error) {
					console.log(error);
				}
			},

			async processAuthenticationResponse(authorizedUser) {
				this.$store.commit('loggedIn', authorizedUser.authToken);

				return AuthService.getProfile()
					.then((res) => {
						console.log(res);
						this.$store.commit('setUser', res)
						this.$router.push({path: '/home', replace: true});
					})
					.catch((err) => {
						console.log(err);
						this.$store.commit('logOut');
						this.$router.push('/sign-in');
					});
			},
		},
		computed: {},
	};
</script>
