<template>
	<v-container fluid>
		<v-row>
			<v-col cols="12">
				<v-progress-circular :indeterminate="true" size="200" color="primary"></v-progress-circular>
			</v-col>
		</v-row>
	</v-container>
</template>

<script>
	import { defineComponent } from 'vue';
	import AuthService from "@/modules/auth/AuthService";

	export default defineComponent({
		name: 'Loader',

		props: [],

		components: {},

		data: function () {
			return {};
		},

		mounted() {
			this.$store.commit('initialized', true);
			AuthService.refreshToken()
					.then((res) => {
						this.handleLoginSuccess(res.authorizedUser);
					})
					.catch(() => {
						this.handleLoginFailure();
					});
		},

		beforeUnmount() {},

		methods: {
			handleLoginSuccess(result) {
				this.$store.commit('loggedIn', result.authToken);
				AuthService.getProfile().then((res) => {
					this.$store.commit('setUser', res);
					this.$router.push(this.$store.state.origRequestedUri);
				}).catch(() => {
					this.$store.commit('logOut')
					this.$router.push('/sign-in');
				})
			},

			handleLoginFailure() {
				this.$router.push('/sign-in');
			},
		},

		computed: {},
	});
</script>

<style scoped lang="scss"></style>
