import { createApp } from 'vue'
import App from './App.vue'

import EventBus from 'js-event-bus';
import router from './router';
import store from './store';
import PIcon from "@/components/PIcon";

import 'maz-ui/css/main.css'
import 'vuetify/styles'
import 'material-design-icons-iconfont/dist/material-design-icons.css'

import { createVuetify } from 'vuetify'
import * as components from 'vuetify/components'
import * as directives from 'vuetify/directives'
import { aliases, md } from 'vuetify/iconsets/md'
import { Chart, registerables } from 'chart.js';
Chart.register(...registerables);

import LuxonAdapter from "@date-io/luxon"
const luxon = new LuxonAdapter({ locale: "en-US" });

import { plugin as VueTippy } from 'vue-tippy'
import 'tippy.js/dist/tippy.css' // optional for styling

/*
Helper Array method to replace the contents in an array with new iterable
 */
Array.prototype.replace = function(arr) {
    this.splice(0);
    this.push(... arr);
}

/*
Add the Google ReCaptcha Script to the HTML
 */
let s = document.createElement( 'script' );
s.setAttribute( 'src', `https://www.google.com/recaptcha/api.js?render=${process.env.VUE_APP_RECAPTCHA_SITE_KEY}`);
document.body.appendChild( s );

const lightTheme = {
    dark: false,
    colors: {
        primary: '#6abe28',
        secondary: '#14142b',
        tertiary: '#FF8945',
        black: '#14142b',
        white: '#FFFFFF',
        gray_10: '#F2F3F3',
        gray_20: '#E6E6E7',
        gray_30: '#E0E0E1',
        gray_50: '#D5D6D7',
        gray_60: '#818388',
        gray_70: '#6C6F74',
        gray_80: '#575A61',
        gray_90: '#42464D',
        modal_overlay: '#2F2E2C',
        success: '#6abe28'
    }
}

const darkTheme = {
    dark: true,
    colors: {
        black: '#f5f5f5',
        white: '#10110F',
        gray_10: '#10110F',
        gray_20: '#2F2E2C',
        gray_30: '#56524E',
        gray_50: '#56524E',
        gray_60: '#D6D1CB',
        gray_70: '#E9E8E0',
        gray_80: '#F4F3EF',
        gray_90: '#F9F8F6',
        modal_overlay: '#10110F',
        success: '#6abe28'
    }
}



const vuetify = createVuetify({
    components,
    directives,
    date: {
        adapter: luxon,
    },
    icons: {
        defaultSet: 'md',
        aliases,
        sets: {
            md,
        },
    },
    display: {
        mobileBreakpoint: 'sm',
    },
    theme: {
        defaultTheme: 'lightTheme',
        themes: {
            lightTheme,
            darkTheme
        }
    }
})

const app = createApp(App).use(router).use(store).use(vuetify);

app.use(
    VueTippy,
    // optional
    {
        directive: 'tippy', // => v-tippy
        component: 'tippy', // => <tippy/>
        componentSingleton: 'tippy-singleton', // => <tippy-singleton/>,
        defaultProps: {
            theme: 'light-border',
            ignoreAttributes: true,
            duration: [500, 100],
            placement: 'top',
            arrow: true,
            arrowType: 'round',
            animation: 'fade',
            delay: 50,
            boundary: 'window',
        }, // => Global default options * see all props
    }
)

app.component('PIcon',PIcon);
app.config.globalProperties.$eventBus = new EventBus();
app.mount('#app');

