<template>
	<div class="wrapper" @click="$emit('action')">
		<div class="header" style="align-items: center;">
			<slot name="header"></slot>
		</div>
		<div class="content mt-2">
			<slot name="content"></slot>
		</div>
		<div class="row-format action-area mt-auto">
			<div class="row-format centered action-button">
				<div class="font-14">{{DateTime.fromISO(sentAt).toLocaleString(DateTime.DATETIME_SHORT)}}</div>
				<div>{{ actionText }}</div>
			</div>
			<div style="margin-left: auto">
				<snooze-delete :id="id" />
			</div>
		</div>
	</div>
</template>

<script>

	import SnoozeDelete from "@/modules/notifications/SnoozeDelete";
	import PDateTime from "@/modules/utils/PDateTime";

	export default {
		name: 'NotificationLayout',

		props: ['id', 'actionText', 'sentAt'],

		emits: ['action'],

		components: { SnoozeDelete },

		data: function () {
			return {
				DateTime: PDateTime
			};
		},

		mounted() {},

		beforeUnmount() {},

		methods: {},

		computed: {},
	};
</script>

<style scoped lang="scss">
	.wrapper {
		width: 100%;
		min-height: 145px;
		height: 100%;
		display: flex;
		flex-direction: column;
		background-color: rgb(var(--v-theme-white));
		//box-shadow: 0px 1px 0px var(--v-white-base);
		border: 1px solid rgb(var(--v-theme-gray_30));
		box-sizing: border-box;
		padding: 12px;
		cursor: pointer;
		border-radius: 12px;

		.action-area {
			opacity: 0;
		}

		&:hover {
			background-color: rgb(var(--v-theme-gray_10));
			border: 1px solid rgb(var(--v-theme-gray_50));
			box-sizing: border-box;
			//box-shadow: 0px 1px 0px var(--v-gray_50-base);


			.action-area {
				opacity: 1;
			}
		}
	}

	.header {
		display: flex;
		color: rgb(var(--v-theme-gray_80));
		flex-direction: row;
		font-size: 14px;
		word-wrap: anywhere;
		border-bottom: 1px solid rgb(var(--v-theme-gray_30));
		padding-bottom: 8px;
		text-align: left;
	}

	.content {
		padding-top: 8px;
		border-radius: 4px;
		display: flex;
		flex-direction: column;
		text-align: left;
		word-wrap: anywhere;
	}

	.action-button {
		color: rgb(var(--v-theme-primary));
		font-size: 14px;
		border-radius: 4px;
		word-wrap: anywhere;
	}
</style>
