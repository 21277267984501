<template>
	<div class="mt-4">
		<div v-if="campaign.status === 'ACTIVE'">
			<div v-if="campaign.isManaged" class="row-format gap-2 my-4">
				<v-autocomplete
					hide-details
					density="compact"
					variant="outlined"
					v-model="importListId"
					:items="filteredDialerLists"
					item-value="id"
					item-title="name"
					persistent-placeholder
					label="Import dialer list"
				></v-autocomplete>
				<v-btn class="secondary-action" :disabled="!importListId" @click="importList">Import</v-btn>
			</div>
			<campaign-list-uploader
				class="my-4"
				@files-dropped="filesDropped"
				@error="filesError"
				v-else
			></campaign-list-uploader>
		</div>

		<v-container fluid class="ma-0 pa-0 text-left font-14">
			<v-row dense class="list-row" style="cursor: unset !important">
				<v-col cols="3" class="font-weight-bold">Name</v-col>
				<v-col cols="2" class="font-weight-bold">Status</v-col>
				<v-col cols="2" class="font-weight-bold">List type</v-col>
				<v-col cols="3" class="font-weight-bold">Date</v-col>
				<v-col cols="2" class="text-right font-weight-bold">Leads</v-col>
			</v-row>
			<v-row
				@click="openList(list)"
				v-for="list in combinedLists"
				:key="list.id"
				:class="`${list.status === 'UPLOADED' ? 'up-loadable' : ''} list-row`"
				dense
				class="py-2"
			>
				<v-col cols="3" class="truncate" v-tippy="`${list.name}`">{{ list.name }}</v-col>
				<v-col cols="2" style="text-transform: capitalize" class="row-format align-center gap-1"
					><div>{{ formatStatus(list) }}</div>
					<v-icon
						size="16"
						class="pointer"
						@click.stop="confirmListDelete(list)"
						v-if="list.status === 'UPLOADED'"
						color="gray_70"
						>delete</v-icon
					></v-col
				>
				<v-col cols="2" style="text-transform: capitalize">{{ formatType(list) }}</v-col>
				<v-col cols="3">{{ DateTime.fromISO(list.uploaded).toLocaleString(DateTime.DATETIME_SHORT) }}</v-col>
				<v-col cols="2" class="text-right" v-tippy="formatTippy(list)"
					>{{ list.metrics.valid }}/{{ getTotal(list.metrics) }}</v-col
				>
				<template v-if="list.expanded">
					<v-col cols="9">
						<template v-if="list.processedBy">
							Processed by:
							<span class="medium">{{ list.processedBy.firstName }} {{ list.processedBy.lastName }}</span
							><br />
							Started:
							<span class="medium">{{
								DateTime.fromISO(list.processingStart).toLocaleString(DateTime.DATETIME_SHORT)
							}}</span
							><br />
							Finish:
							<span class="medium" v-if="list.processingFinish">{{
								DateTime.fromISO(list.processingFinish).toLocaleString(DateTime.DATETIME_SHORT)
							}}</span
							><span v-else style="font-style: italic">In process</span><br />
							Total time: <span class="medium">{{ formatSeconds(list.processingDuration) }}</span
							><br />
							<div
								class="font-secondary font-weight-bold pointer mt-4"
								@click.stop="downloadProcessedFile(list)"
								v-if="list.processedFile"
							>
								Download processed file
							</div>
						</template>
					</v-col>
					<v-col cols="3" class="text-right">
						Supplied: <span class="medium">{{ getTotal(list.metrics) }}</span
						><br />
						Landline: <span class="medium">{{ list.metrics.landline }}</span
						><br />
						Mobile: <span class="medium">{{ list.metrics.mobile }}</span
						><br />
						Inactive: <span class="medium">{{ list.metrics.inactive }}</span>
						<br />
						Invalid: <span class="medium">{{ list.metrics.invalid }}</span>
						<br />
						TCPA Risk: <span class="medium">{{ list.metrics.tcpa }}</span>
						<br />
						Do Not Call: <span class="medium">{{ list.metrics.dnc }}</span>
						<br />
						Token Error: <span class="medium">{{ list.metrics.tokenError }}</span>
						<br />
						Duplicate check: <span class="medium">{{ list.metrics.duplicate }}</span>
						<br />
						<span v-if="isAPNDialer">
							Dialer rejected: <span class="medium">{{ list.metrics.rejected }}</span>
						</span>
					</v-col>
					<template v-if="list.messagingList">
						<v-col cols="5" style="background-color: rgb(var(--v-theme-gray_10))" class="pl-2">
							Messaging status: <span style="font-weight: 600">{{ list.messagingList.listStatus }}</span
							><br />
						</v-col>
						<v-col cols="2" class="column-format" style="background-color: rgb(var(--v-theme-gray_10))">
							<p-icon
								color="primary"
								size="40"
								v-if="list.messagingList.listStatus === 'READY' || list.messagingList.status === 'PAUSED'"
								class="pointer"
								@click.stop="confirmMessagingStart(list)"
								>play_circle</p-icon
							>
							<p-icon
								color="error"
								size="40"
								v-if="list.messagingList.listStatus === 'PROCESSING'"
								class="pointer"
								@click.stop="confirmMessagingStart(list)"
								>pause_circle</p-icon
							>
						</v-col>
						<v-col cols="5" class="text-right pr-2" style="background-color: rgb(var(--v-theme-gray_10)">
							Sent: <span class="medium">{{ list.messagingList.metrics.sent }}</span
							><br />
							Delivered: <span class="medium">{{ list.messagingList.metrics.delivered }}</span
							><br />
							Failed: <span class="medium">{{ list.messagingList.metrics.failed }}</span
							><br />
						</v-col>
						<v-col cols="12" style="background-color: rgb(var(--v-theme-gray_10))" class="px-2">
							<v-container fluid class="pa-0 ma-0">
								<v-row dense v-for="(event, index) in list.messagingList.events" :key="index">
									<v-col cols="3">{{
										DateTime.fromISO(event.timestamp).toLocaleString(DateTime.DATETIME_SHORT)
									}}</v-col>
									<v-col cols="2">{{ event.type }}</v-col>
									<v-col cols="7">{{ event.message }}</v-col>
								</v-row>
							</v-container>
						</v-col>
					</template>
				</template>
			</v-row>
		</v-container>
	</div>
</template>

<script>
	import { defineComponent } from 'vue';
	import CampaignListService from '@/modules/campaigns/CampaignListService';
	import PDateTime from '@/modules/utils/PDateTime';
	import CampaignListUploader from '@/modules/campaigns/CampaignListUploader';
	import ListEdit from '@/modules/campaigns/ListEdit';
	import MessagingListService from '@/modules/campaigns/MessagingListService';
	import PIcon from '@/components/PIcon';
	import ConfirmModal from '@/components/ConfirmModal';
	import WorkspaceService from '@/modules/workspaces/WorkspaceService';
	import DialerService from '@/modules/dialers/DialerService';

	export default defineComponent({
		name: 'CampaignLists',

		props: ['workspaceId', 'campaign'],

		components: { PIcon, CampaignListUploader },

		data: function () {
			return {
				campaignListService: new CampaignListService(),
				messagingListService: new MessagingListService(),
				workspaceService: new WorkspaceService(),
				lists: [],
				dialerLists: [],
				importListId: null,
				messagingLists: [],
				DateTime: PDateTime,
				dialerProviderType: null,
			};
		},

		mounted() {
			this.getCampaignLists();
			this.getMessagingLists();
			this.getDialerProviderType();
			this.getDialerLists();
		},

		beforeUnmount() {},

		methods: {
			getDialerLists() {
				if (this.campaign.isManaged) {
					new DialerService().getCampaign(this.workspaceId, this.campaign.dialerCampaignId).then((res) => {
						if (res && res.data) {
							this.dialerLists.replace(res.data.lists);
						}
					});
				}
			},

			async importList() {
				try {
					this.$store.commit('startLoading');
					let list = this.dialerLists.find((d) => d.id === this.importListId);

					let importRequest = {
						dialerListId: this.importListId,
						dialerListName: list?.name,
					};

					let result = await this.campaignListService.importList(this.workspaceId, this.campaign.id, importRequest);
					this.lists.push(result.data);
					this.sortList();
					this.$store.commit('success',`${importRequest.dialerListName} imported successfully`);
				}catch(err){
					this.$store.commit('error', err.response.data.message);
				}finally{
					this.$store.commit('stopLoading');
				}
			},

			confirmListDelete(list) {
				let binding = {
					headingText: 'Confirm!',
					bodyText: `Are you sure you want to delete ${list.name}?`,
				};
				this.$store.state.globalModalController.openModal(ConfirmModal, binding).then((res) => {
					if (res) {
						this.campaignListService.deleteCampaignList(this.workspaceId, this.campaign.id, list.id).then(() => {
							let ix = this.lists.findIndex((l) => l.id === list.id);
							if (ix > -1) {
								this.lists.splice(ix, 1);
							}
						});
					}
				});
			},

			async downloadProcessedFile(list) {
				let result = await this.campaignListService.downloadProcessedFile(this.workspaceId, this.campaign.id, list.id);
				let fileURL = window.URL.createObjectURL(result.data);
				let fileLink = document.createElement('a');
				fileLink.href = fileURL;
				fileLink.setAttribute('download', 'Processed-' + list.name);
				document.body.appendChild(fileLink);
				fileLink.click();
			},

			async filesDropped(files) {
				this.$store.commit('startLoading');
				try {
					for (const file of files) {
						let result = await this.campaignListService.uploadCampaignList(this.workspaceId, this.campaign.id, file);
						this.lists.push(result.data);
					}
					this.sortList();
					this.openList(this.lists[0]);
				} catch (err) {
					this.$store.commit('error', err.response.data.message);
				} finally {
					this.$store.commit('stopLoading');
				}
			},

			listUpdated: function (list) {
				let ix = this.lists.findIndex((l) => l.id === list.id);
				if (ix > -1) {
					this.lists.splice(ix, 1, list);
				}
			},

			messagingListUpdated: function (messagingList) {
				let ix = this.messagingLists.find((l) => l.id === messagingList.id);
				if (ix > -1) {
					this.messagingLists.splice(ix, 1, messagingList);
				} else {
					this.messagingLists.push(messagingList);
				}
			},

			filesError: function (error) {
				this.$store.commit('error', error);
			},

			formatTippy(list) {
				return `Loaded: ${list.metrics.valid}\nSupplied: ${this.getTotal(list.metrics)}\nInactive: ${
					list.metrics.inactive
				}\nInvalid: ${list.metrics.invalid}`;
			},

			formatStatus(list) {
				if (list.messagingList) {
					let prefix = list.type === 'COMBINED' ? list.status.toLowerCase() + ' / ' : '';
					let suffix = '';
					switch (list.messagingList.listStatus) {
						case 'READY':
							suffix = 'Messaging ready';
							break;
						case 'PAUSED':
							suffix = 'Messaging paused';
							break;
						case 'PROCESSING':
							suffix = 'Messaging sending';
							break;
						case 'FINISHED':
							suffix = 'Messaging finished';
							break;
						default:
							suffix = list.messagingList.listStatus.toLowerCase();
					}
					return prefix + suffix;
				} else {
					return list.status.toLowerCase();
				}
			},

			formatType(list) {
				if (list.type === 'DIALER') {
					return 'Dialer';
				} else if (list.type === 'MESSAGING') {
					return 'Messaging';
				} else {
					return 'Combined';
				}
			},

			async getDialerProviderType() {
				let result = await this.workspaceService.getWorkspace(this.workspaceId);
				this.dialerProviderType = result.data.dialerProviderType;
			},

			async getCampaignLists() {
				let result = await this.campaignListService.getCampaignLists(this.workspaceId, this.campaign.id);
				this.lists.replace(result.data);
				this.lists.forEach((l) => {
					l.expanded = false;
				});
				this.sortList();
			},

			async getMessagingLists() {
				let result = await this.messagingListService.getMessagingLists(this.workspaceId, this.campaign.id);
				this.messagingLists.replace(result.data);
			},

			sortList: function () {
				this.lists.sort((a, b) => {
					if (b.uploaded && a.uploaded) {
						return b.uploaded.localeCompare(a.uploaded);
					} else if (b.uploaded) {
						return 1;
					} else if (a.uploaded) {
						return -1;
					} else {
						return 0;
					}
				});
			},

			getTotal: function (metrics) {
				return metrics.total;
			},

			openList: function (list) {
				if (list.status !== 'UPLOADED') {
					list.expanded = !list.expanded;
					return;
				}

				let binding = {
					workspaceId: this.workspaceId,
					campaignId: this.campaign.id,
					dialerCampaignId: this.campaign.dialerCampaignId,
					id: list.id,
					list: list,
				};
				this.$store.state.globalModalController.openModal(ListEdit, binding, true, true, false, true);
			},

			confirmMessagingStart: function (campaignList) {
				let binding = {
					headingText: 'Mobile A2P Broadcast',
					bodyText: `You you like to ${
						campaignList.messagingList.listStatus === 'READY' ? 'begin' : 'resume'
					} broadcast text messaging to ${campaignList.metrics.mobile} contacts on this list?`,
				};
				this.$store.state.globalModalController.openModal(ConfirmModal, binding).then((res) => {
					if (res) {
						this.messagingListService
							.startMessagingToList(
								campaignList.workspaceId,
								campaignList.campaignId,
								campaignList.messagingList.id
							)
							.then(() => {
								this.$store.commit('success', 'Broadcast A2P Messaging Started.');
							})
							.catch((err) => {
								this.$store.commit('error', err.response.data.message);
							});
					}
				});
			},

			formatSeconds(seconds) {
				let hours = Math.floor(seconds / 3600);
				let minutes = Math.floor((seconds - hours * 3600) / 60);
				seconds = seconds - hours * 3600 - minutes * 60;

				if (hours < 10) {
					hours = '0' + hours;
				}
				if (minutes < 10) {
					minutes = '0' + minutes;
				}
				if (seconds < 10) {
					seconds = '0' + seconds;
				}
				return hours + ':' + minutes + ':' + seconds;
			},
		},

		computed: {
			filteredDialerLists: function(){
				let importedLists = this.lists.map(l => l.dialerListId);
				return this.dialerLists.filter(obj => !importedLists.includes(obj.id));
			},

			combinedLists: function () {
				let result = [...this.lists];

				if (this.messagingLists.length) {
					result.forEach((r) => {
						r.messagingList = this.messagingLists.find((m) => m.campaignListId === r.id);
					});
				}

				return result;
			},

			isAPNDialer: function () {
				return this.dialerProviderType === 'APN';
			},
		},
	});
</script>

<style scoped lang="scss">
	.list-row {
		border-bottom: 1px solid rgb(var(--v-theme-gray_50));
		cursor: pointer;
	}

	.up-loadable {
		&:hover {
			background-color: rgb(var(--v-theme-gray_30));
		}
	}
</style>
