<template>
	<v-navigation-drawer
		:modelValue="drawer"
		location="right"
		permanent
		:class="`notifications-drawer`"
		:style="`${mobile ? 'width:98% !important;' : ''}`"
	>
		<div :class="`notifications-container ${row ? 'row-format' : 'column-format'}`">
			<div class="notifications-card" v-for="item in sortedNotifications" :key="item.id">
				<component :key="item.notificationType" :is="item.notificationType" v-bind="item" style="height: fit-content" />
			</div>

			<div v-if="sortedNotifications.length === 0" class="column-format centered mb-6 mt-6" style="align-self: center">
				<p-icon>check</p-icon>
				<div class="font-gray_70">You're up to date!</div>
				<!-- <v-btn @click="notificationService.sendTestNotification()">Do A Test!</v-btn> -->
			</div>
		</div>
	</v-navigation-drawer>
</template>

<script>
	import NotificationService from './NotificationService';
	import GenericNotification from './widgets/GenericNotification';
	import ListStatusNotification from '@/modules/notifications/widgets/ListStatusNotification';
	import CampaignStatusNotification from '@/modules/notifications/widgets/CampaignStatusNotification';

	export default {
		name: 'Notifications',

		props: ['isVisible', 'row', 'drawer', 'mobile'],

		components: {
			GenericNotification,
			ListStatusNotification,
			CampaignStatusNotification,
		},

		data: function () {
			return {
				notificationService: new NotificationService(),
			};
		},

		beforeUnmount() {},

		methods: {
			deleteAll: function () {
				this.notificationService.deleteNotifications();
			},

			markAllAsRead: function () {
				this.notificationService.markAllAsRead();
			},

			sortFunction: function (a, b) {
				return b.sentAt.localeCompare(a.sentAt);
			},
		},

		computed: {
			notifications: function () {
				return this.$store.state.notifications;
			},

			sortedNotifications: function () {
				let newArray = [...this.$store.state.notifications];
				newArray.sort(this.sortFunction);
				return newArray;
			},

			unreadNotificationCount: function () {
				return this.$store.getters.unreadNotificationCount;
			},
		},

		watch: {
			isVisible: {
				immediate: true,
				handler(newVal) {
					if (newVal && this.unreadNotificationCount > 0) {
						this.markAllAsRead();
					}
				},
			},
		},
	};
</script>

<style lang="scss">
	.notifications-drawer {
		margin-top: 55px;
		padding: 5px;
		.notifications-container {
			min-height: calc(100vh - 60px);
			max-height: calc(100vh - 60px);
			height: calc(100vh - 60px);
			overflow-y: auto;
			.notifications-card {
				margin-bottom: 10px;
			}
		}
	}
	.rule {
		border-top: 1px solid rgb(var(--v-theme-gray_50));
		border-bottom: none;
	}
</style>
