<template>
	<v-container fluid>
		<v-row>
			<v-col cols="12" class="row-format">
				<module-header name="Usage report"></module-header>
				<v-select style="max-width: 250px" :items="dateOptions" v-model="datePeriod" item-value="value" item-title="text" hide-details variant="outlined" density="compact"></v-select>
			</v-col>
		</v-row>
		<v-row style="max-height: calc(100vh - 130px); overflow-y: auto">
			<v-col cols="12">
				<v-data-table
						id="usage-table"
						:items="usageReport"
						v-model:items-per-page="itemsPerPage"
						:headers="headers"
						class="pointer"
						fixed-header
				>
					<template v-slot:[`item.rate`]="{ item }">{{dollars(item.rate)}}</template>
					<template v-slot:[`item.total`]="{ item }">{{dollars(item.total)}}</template>
					<template v-slot:[`body.append`]>
						<tr>
							<td class="font-weight-bold text-right" colspan="3">Total:</td>
							<td class="font-weight-bold text-right">{{dollars(usageReport.reduce((accumulator, currentObject) => {
								return accumulator + currentObject.total;
							}, 0))}}</td>
							</tr>
					</template>
				</v-data-table>
			</v-col>
		</v-row>
	</v-container>
</template>

<script>
import {defineComponent} from 'vue';
import ModuleHeader from "@/components/ModuleHeader";
import FilterHelpers from "@/modules/utils/FilterHelpers";
import OrganizationService from "@/modules/organization/OrganizationService";

export default defineComponent({
	name: 'UsageReport',

	props: [],

	components: {ModuleHeader},

	data: function () {
		return {
			orgService: new OrganizationService(),
			usageReport: [],
			datePeriod: 'this-month',
			itemsPerPage: -1,
			dateOptions: FilterHelpers.getDateOptions([
				'this-month',
				'last-month'
			]),

			headers: [
				{ title: 'Description', key: 'description', align: 'start' },
				{ title: 'Quantity', key: 'qty', align: 'end' },
				{ title: 'Rate', key: 'rate', align: 'end' },
				{ title: 'Total', key: 'total', align: 'end' },
			],
		};
	},

	mounted() {
		this.getBillableUsage();
	},

	beforeUnmount() {
	},

	methods: {
		async getBillableUsage(){
			this.usageReport.splice(0);
			let lineItems = await this.orgService.getBillableUsage(this.earliest.toISO(),this.latest.toISO());
			this.usageReport.replace(lineItems);
		},

		dollars(amount = 0, minimumFractionDigits = 4, currencySign = true, currency = 'USD') {
			try {
				amount = amount.toString().replace(/[^-0-9.]/g, '');
				if (isNaN(amount)) amount = 0;

				if (currencySign) {
					return new Intl.NumberFormat('en-US', {
						style: 'currency',
						currency: currency,
						currencySign: 'accounting',
						maximumFractionDigits: minimumFractionDigits,
					}).format(amount);
				} else {
					return new Intl.NumberFormat('en-US', {
						style: 'currency',
						currency: currency,
						maximumFractionDigits: minimumFractionDigits,
					}).format(amount).replace(/[^0-9.,]/g, '');
				}
			}catch(err){
				console.log('Error formatting currency',err);
				return amount;
			}
		}
	},

	watch: {
		datePeriod: function(){
			this.getBillableUsage();
		}
	},

	computed: {
		earliest: function(){
			let helper = FilterHelpers.getEarliestAndLatest(this.datePeriod, false, true);
			return helper.earliest;
		},

		latest: function(){
			let helper = FilterHelpers.getEarliestAndLatest(this.datePeriod, false, true);
			return helper.latest;
		},
	},
});
</script>

<style scoped lang="scss">
	#usage-table :deep(.v-data-table-footer) {
		display: none;
	}
</style>
