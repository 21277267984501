import {DateTime} from 'luxon';

export default class FilterHelpers {
	constructor() {
		//if (phase) this.phase = phase.toLowerCase();
	}

	static dateOptions = [
		{ text: 'Today', value: 'today' },
		{ text: 'Yesterday', value: 'yesterday' },
		{ text: 'This week', value: 'this-week' },
		{ text: 'Last week', value: 'last-week' },
		{ text: DateTime.local().toLocaleString({month:'long', year:'numeric'}), value: 'this-month' },
		{
			text: DateTime.local().minus({ month: 1 }).toLocaleString({month:'long', year:'numeric'}),
			value: 'last-month',
		},
		{ text: 'Next 30 days', value: 'next-30-days' },
		{ text: 'Next 60 days', value: 'next-60-days' },
		{ text: 'Next 90 days', value: 'next-90-days' },
		{ text: 'Past 30 days', value: 'past-30-days' },
		{ text: 'Past 60 days', value: 'past-60-days' },
		{ text: 'Past 90 days', value: 'past-90-days' },
		{ text: 'Past 6 months', value: 'past-6-months' },
		{ text: 'Past 12 months', value: 'past-12-months' },
		{ text: 'This quarter', value: 'this-quarter'},
		{ text: 'Year to date', value: 'year-to-date'},
		{ text: 'Last quarter', value: 'last-quarter'},
		{
			text: DateTime.local().toFormat('yyyy'),
			value: 'this-year',
		},
		{
			text: DateTime.local().minus({ year: 1 }).toFormat('yyyy'),
			value: 'last-year',
		},
		{ text: 'All time', value: 'all-time'},
		{ text: 'Custom', value: 'between' },
	];

	static getDateOptions(options) {
		let dateOptions = [];
		for (let i = 0; i < this.dateOptions.length; i++) {
			let option = this.dateOptions[i];
			if (options.includes(option['value'])) dateOptions.push(option);
		}
		return dateOptions;
	}

	static getQuarter(relative='current'){
		let today = DateTime.now();

		if(relative === 'previous'){
			today = today.minus({month:3});
		}

		let month = today.month;
		let quarter = Math.floor((month + 2) / 3);
		let monthStart = (quarter * 3) - 2;
		let diff = month - monthStart;

		let startTime = today.minus({month:diff}).startOf('month');
		let endTime = startTime.plus({month:3}).minus({millisecond:1});

		return {
			earliest: startTime,
			latest: endTime
		}
	}


	static getEarliestAndLatest(dateSpecifier, isoFormat = true) {
		let now = DateTime.local().toObject();
		const today = DateTime.fromObject({ year: now.year, month: now.month, day: now.day });
		const firstOfWeek = DateTime.local().startOf('week');
		const firstOfMonth = DateTime.local().startOf('month');
		// if (!selectedFilters) selectedFilters = this.selectedFiltersDefault;
		let earliest, latest;
		switch (dateSpecifier) {
			case 'earlier':
				earliest = today.minus({ years: 1000 });
				latest = today.minus({ second: 1 });
				break;
			case 'today':
				earliest = today;
				latest = today.plus({ days: 1 });
				break;
			case 'yesterday':
				earliest = today.minus({ days: 1 });
				latest = today;
				break;
			case 'this-week':
				earliest = firstOfWeek;
				latest = today.endOf('week');
				break;
			case 'last-week':
				earliest = firstOfWeek.minus({ week: 1 });
				latest = earliest.endOf('week');
				break;
			case 'last-two-weeks':
				earliest = firstOfWeek.minus({ week: 1 });
				latest = today.endOf('week');
				break;
			case 'this-month':
				earliest = firstOfMonth;
				latest = firstOfMonth.endOf('month')
				break;
			case 'month-to-date':
				earliest = firstOfMonth;
				latest = today.endOf('day');
				break;
			case 'last-month':
				earliest = firstOfMonth.minus({ month: 1 });
				latest = firstOfMonth.minus({seconds:1});
				break;
			case 'next-30-days':
				earliest = today;
				latest = today.plus({ days: 30 });
				break;
			case 'next-60-days':
				earliest = today;
				latest = today.plus({ days: 60 });
				break;
			case 'next-90-days':
				earliest = today;
				latest = today.plus({ days: 90 });
				break;
			case 'past-30-days':
				earliest = today.minus({ days: 30 });
				latest = today.plus({ days: 1 }).endOf('day');
				break;
			case 'past-60-days':
				earliest = today.minus({ days: 60 });
				latest = today.plus({ days: 1 }).endOf('day');
				break;
			case 'past-90-days':
				earliest = today.minus({ days: 90 });
				latest = today.plus({ days: 1 }).endOf('day');
				break;
			case 'past-6-months':
				earliest = today.minus({ month: 5 }).startOf('month');
				latest = today.plus({ days: 1 }).endOf('month');
				break;
			case 'past-12-months':
				earliest = today.minus({ month: 11 }).startOf('month');
				latest = today.plus({ days: 1 }).endOf('month');
				break;
			case 'this-quarter': {
				let quarter = this.getQuarter();
				earliest = quarter.earliest;
				latest = quarter.latest;
				break;
			}
			case 'last-quarter': {
				let quarter = this.getQuarter('previous');
				earliest = quarter.earliest;
				latest = quarter.latest;
				break;
			}
			case 'this-year':
				earliest = today.startOf('year');
				latest = today.endOf('year');
				break;
			case 'year-to-date':
				earliest = today.startOf('year');
				latest = today.endOf('day');
				break;
			case 'last-year':
				earliest = today.startOf('year').minus({ year: 1 });
				latest = earliest.endOf('year');
				break;
			case 'all-time':
				earliest = today.startOf('year').minus({ year: 1000 });
				latest = earliest.endOf('year').plus({year: 1000});
				break;
			default:
				return;
		}

		if (isoFormat) {
			return { earliest: earliest.toISO(), latest: latest.toISO() };
		} else {
			return { earliest: earliest, latest: latest };
		}
	}
}
