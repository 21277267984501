<template>
	<v-container fluid>
		<v-row>
			<v-col cols="12" class="row-format">
				<module-header name="Billing profile"></module-header>
				<v-btn elevation="0" class="ml-auto" style="min-height: 40px" color="secondary" @click="saveBillingProfile()" v-if="!disabled"
					>Save</v-btn
				>
			</v-col>
		</v-row>
		<v-row v-if="billingProfile" style="max-height: calc(100vh - 130px); overflow-y: auto">
			<v-col cols="12" class="column-format gap-3 pl-10">
				<div class="row-format align-center">
					<div class="text-left font-14" style="width: 200px; max-width: 200px; min-width: 200px">Platform fee</div>
					<v-text-field
						number
						hide-details
						variant="outlined"
						density="compact"
						persistent-placeholder
						prefix="$"
						v-model="billingProfile.platformFee"
						:disabled="disabled"
						oninput="this.value = this.value.replace(/[^0-9.]/g, '').replace(/(\..*?)\..*/g, '$1');"
					></v-text-field>
				</div>
				<div class="row-format align-center">
					<div class="text-left font-14" style="width: 200px; max-width: 200px; min-width: 200px">Campaign fee</div>
					<v-text-field
							number
							hide-details
							variant="outlined"
							density="compact"
							persistent-placeholder
							prefix="$"
							v-model="billingProfile.campaignFee"
							:disabled="disabled"
							oninput="this.value = this.value.replace(/[^0-9.]/g, '').replace(/(\..*?)\..*/g, '$1');"
					></v-text-field>
				</div>
				<div class="row-format align-center">
					<div class="text-left font-14" style="width: 200px; max-width: 200px; min-width: 200px">Support fee</div>
					<v-text-field
						number
						hide-details
						variant="outlined"
						density="compact"
						persistent-placeholder
						prefix="$"
						v-model="billingProfile.supportFee"
						:disabled="disabled"
						oninput="this.value = this.value.replace(/[^0-9.]/g, '').replace(/(\..*?)\..*/g, '$1');"
					></v-text-field>
				</div>
				<rate label="DataBoost" v-model="billingProfile.dataBoost" :disabled="disabled"></rate>
				<rate label="Calls (min)" v-model="billingProfile.calls" :disabled="disabled"></rate>
				<rate label="SMS Engage" v-model="billingProfile.messages" :disabled="disabled"></rate>
				<rate label="Watchtower Guard" v-model="billingProfile.watchTowerDnc" :disabled="disabled"></rate>
				<rate label="Watchtower Scan" v-model="billingProfile.watchTowerToken" :disabled="disabled"></rate>
			</v-col>
			<v-col cols="10" class="text-left" style="border-top: 1px solid rgb(var(--v-theme-gray_30))">
				<div class="brand-medium">Core transactions</div>
			</v-col>
			<v-col cols="12" class="pl-10">
				<rate v-model="billingProfile.transactions" :disabled="disabled"></rate>
			</v-col>
			<v-col cols="10" class="text-left" style="border-top: 1px solid rgb(var(--v-theme-gray_30))">
				<div class="brand-medium">UC Protect numbers</div>
			</v-col>
			<v-col cols="12" class="pl-10">
				<rate v-model="billingProfile.protect" :disabled="disabled" :enable-minimum="true"></rate>
			</v-col>
			<v-col cols="10" class="text-left" style="border-top: 1px solid rgb(var(--v-theme-gray_30))">
				<div class="brand-medium">Aegis transactions</div>
			</v-col>
			<v-col cols="12" class="pl-10">
				<rate v-model="billingProfile.aegis" :disabled="disabled" :enable-minimum="false"></rate>
			</v-col>
		</v-row>
	</v-container>
</template>

<script>
	import { defineComponent } from 'vue';
	import OrganizationService from '@/modules/organization/OrganizationService';
	import ModuleHeader from '@/components/ModuleHeader';
	import Rate from '@/modules/organization/Rate';

	export default defineComponent({
		name: 'BillingProfile',

		props: [],

		components: { ModuleHeader, Rate },

		data: function () {
			return {
				orgService: new OrganizationService(),
				billingProfile: null,
			};
		},

		mounted() {
			this.getBillingProfile();
		},

		beforeUnmount() {},

		methods: {
			async getBillingProfile() {
				this.billingProfile = await this.orgService.getBillingProfile();
			},

			async saveBillingProfile(){
				try {
					this.billingProfile = await this.orgService.updateBillingProfile(this.billingProfile);
					this.$store.commit('success', 'Saved');
				}catch(err){
					this.$store.commit('error','Error: ' + err.response.data.message);
				}
			}
		},

		computed: {
			disabled: function () {
				return this.$store.state.user.userType !== 'SuperAdmin';
			},
		},
	});
</script>

<style scoped lang="scss"></style>
