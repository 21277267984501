<template>
	<div v-if="!mobile" id="top-bar" class="gap-4 pl-3 pr-4">
		<p-icon v-if="!hideMenu" class="pointer" color="gray_80" @click="toggleLeftNav()">menu</p-icon>

		<div class="row-format align-center">
			<img src="/images/logo.png" style="max-width: 135px" />
			<div style="margin-left: 17px; border-left: 1px solid rgb(var(--v-theme-secondary))">&nbsp;</div>
			<div style="margin-left: 12px; font-weight: 500; font-size: 22px; color: rgb(var(--v-theme-primary))">
				{{ $store.state.selectedApp }}
			</div>
		</div>
		<div class="ml-auto row-format align-center gap-2">
			<div class="pointer top-button" @click="toggleNotifications">
				<p-icon class="pointer" size="24">notifications_active</p-icon>
				<div class="top-umi" v-if="$store.getters.unreadNotificationCount">
					<div>{{ $store.getters.unreadNotificationCount }}</div>
				</div>
			</div>

			<v-menu location="bottom" content-class="workspace-menu">
				<template v-slot:activator="{ props }">
					<div class="pointer top-button" v-bind="props">
						<p-icon color="secondary" size="26">apps</p-icon>
					</div>
				</template>
				<div class="pa-4 column-format gap-4">
					<div class="row-format gap-4">
						<div class="app" @click="setApp('Core')">
							<div class="app-icon"><img alt="core" src="/images/icon.png" style="max-width: 30px" /></div>
							<div class="app-label">Core</div>
						</div>
						<div class="app" @click="setApp('Aegis One')">
							<div class="app-icon"><img alt="core" src="/images/aegis-icon.png" style="max-width: 30px" /></div>
							<div class="app-label">Aegis One</div>
						</div>

						<div class="app" @click="setApp('Analytics')">
							<div class="app-icon"><p-icon>monitoring</p-icon></div>
							<div class="app-label">Analytics</div>
						</div>
						<div class="app" @click="setApp('Admin')">
							<div class="app-icon"><p-icon>settings</p-icon></div>
							<div class="app-label">Admin</div>
						</div>
					</div>
				</div>
			</v-menu>

			<v-menu location="bottom" content-class="workspace-menu">
				<template v-slot:activator="{ props }">
					<div class="pointer top-button">
						<p-icon color="secondary" size="26" class="pointer" v-bind="props">person</p-icon>
					</div>
				</template>
				<user-menu></user-menu>
			</v-menu>
		</div>
	</div>
	<mobile-nav v-if="mobile"></mobile-nav>
</template>

<script>
	import { defineComponent } from 'vue';
	import PIcon from '@/components/PIcon';
	import MobileNav from './MobileNav.vue';
	import UserMenu from '@/modules/nav/UserMenu';
	import { useDisplay } from 'vuetify';

	export default defineComponent({
		name: 'TopNav',

		props: {
			hideMenu: { type: Boolean, required: false, default: false },
		},

		components: { UserMenu, PIcon, MobileNav },

		data: function () {
			return {
				settingsMenu: false,
			};
		},

		mounted() {},

		beforeUnmount() {},

		methods: {
			setApp: function(app){
				localStorage.setItem('SELECTED_APP',app);
				this.$store.state.selectedApp = app;
			},

			toggleLeftNav: function () {
				this.$store.commit('toggleLeftNav');
			},

			toggleNotifications: function () {
				this.$store.commit('toggleNotifications');
			},
		},

		computed: {
			mobile: () => useDisplay().mobile.value,
		},
	});
</script>

<style lang="scss">
	#top-bar {
		min-height: 48px;
		display: flex;
		flex-direction: row;
		border-bottom: 1px solid rgb(var(--v-theme-gray_50));
		align-items: center;
		//box-shadow: 0px 3px 7px rgba(0, 0, 0, 0.1);
		position: sticky;
		top: 0;
		background: rgb(var(--v-theme-white));
		z-index: 10;
	}

	.workspace-menu {
		filter: drop-shadow(0px 4px 16px rgba(0, 0, 0, 0.2));
		box-shadow: none !important;
		border-radius: 4px !important;
		border: 1px solid rgb(var(--v-theme-gray_50));
		background-color: rgb(var(--v-theme-white));
	}

	.top-button {
		width: 32px;
		height: 32px;
		border-radius: 8px;
		display: flex;
		flex-direction: column;
		justify-content: center;
		align-items: center;
		position: relative;
		&:hover {
			background-color: rgb(var(--v-theme-gray_10));
		}
	}

	.top-umi {
		display: flex;
		flex-direction: row;
		align-items: center;
		justify-content: center;
		font-size: 12px;
		position: absolute;
		top: 0px;
		right: 0px;
		font-weight: 500;
		color: rgb(var(--v-theme-white));
		background-color: rgb(var(--v-theme-primary));
		width: 16px;
		height: 16px;
		border-radius: 16px;
	}

	.app {
		display: flex;
		flex-direction: column;
		align-items: center;
		gap: 3px;
		cursor: pointer;
		.app-icon {
			height: 60px;
			width: 60px;
			display: flex;
			flex-direction: column;
			justify-content: center;
			align-items: center;
			border: 1px solid rgb(var(--v-theme-gray_20));
			border-radius: 8px;
			padding: 8px;
		}

		&:hover {
			.app-icon {
				background-color: rgb(var(--v-theme-gray_20));
				border: 1px solid rgb(var(--v-theme-gray_50));
			}
		}

		.app-label {
			font-size: 12px;
			color: rgb(var(--v-theme-secondary));
		}
	}
</style>
