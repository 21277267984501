import axios from 'axios';
import store from '@/store';
import router from '@/router';
import AuthService from '../modules/auth/AuthService';
import createAuthRefreshInterceptor from 'axios-auth-refresh';

const refreshLogic = (failedRequest) => {
	return AuthService.refreshToken()
		.then((res) => {
			let authToken = res.authorizedUser.authToken;
			store.commit('loggedIn', authToken);
			failedRequest.response.config.headers['Authorization'] = 'Bearer ' + authToken;
			return Promise.resolve();
		})
		.catch((err) => {
			console.log(err);
			store.commit('logOut');
			router.push('/sign-in');
			return Promise.reject();
		});
};

const getClient = (baseUrl = null, useAccessToken = true) => {
	const options = {
		baseURL: baseUrl ? baseUrl : store.getters.getBaseUrl,
		withCredentials: true,
	};

	const client = axios.create(options);

	if (useAccessToken) {
		createAuthRefreshInterceptor(client, refreshLogic);
	}

	if (useAccessToken && store.state.accessToken) {
		client.interceptors.request.use((request) => {
			request.headers['Authorization'] = `Bearer ${store.state.accessToken}`;
			return request;
		});
	}

	client.interceptors.response.use(
		function (response) {
			return response;
		},
		function (error) {
			return Promise.reject(error);
		}
	);

	return client;
};

/**
 * Base HTTP Client
 */
export default class HttpClient {
	baseUrl;
	useAccessToken;
	version;

	constructor(baseUrl = null, useAccessToken = true, version = 1) {
		this.baseUrl = baseUrl;
		this.useAccessToken = useAccessToken;
		this.version = version;
	}

	// Provide request methods with the default base_url
	async get(url, conf = {}) {
		return await getClient(this.baseUrl, this.useAccessToken).get(url, conf);
	}

	async delete(url, conf = {}) {
		return await getClient(this.baseUrl, this.useAccessToken).delete(url, conf)
	}

	async head(url, conf = {}) {
		return await getClient(this.baseUrl, this.useAccessToken).head(url, conf)
	}

	async options(url, conf = {}) {
		return await getClient(this.baseUrl, this.useAccessToken).options(url, conf)
	}

	async post(url, data = {}, conf = {}) {
		return await getClient(this.baseUrl, this.useAccessToken).post(url, data, conf)
	}

	async put(url, data = {}, conf = {}) {
		return await getClient(this.baseUrl, this.useAccessToken).put(url, data, conf)
	}

	async patch(url, data = {}, conf = {}) {
		return await getClient(this.baseUrl, this.useAccessToken).patch(url, data, conf)
	}
}
