import HttpClient from "@/services/HttpClient";

export default class OrganizationService {
    client;

    constructor() {
        this.client = new HttpClient();
    }

    async getOrganization() {
        return await this.client.get('');

    }

   async updateOrganization(organization) {
        return await this.client.put('',organization);
    }

    async getBillingProfile() {
        let result =  await this.client.get('/billingProfile');
        return result.data;
    }

    async updateBillingProfile(billingProfile) {
        let result =  await this.client.put('/billingProfile',billingProfile);
        return result.data;
    }

    async getBillableUsage(earliest,latest) {
        let result =  await this.client.get('/billableUsage',{
            params: {
                earliest: earliest,
                latest: latest
            }});
        return result.data;
    }
}