<template>
	<div style="width: 500px" v-if="campaign">
		<div class="modal-padding column-format gap-2">
			<div class="modal-title row-format align-center">
				<div class="font-18 brand-medium">Create new campaign</div>
				<p-icon class="ml-auto pointer" @click.prevent="$emit('result')">close</p-icon>
			</div>
			<div class="column-format gap-3">
				<v-text-field
					ref="name"
					label="Campaign name"
					v-model="campaign.name"
					:rules="required"
					density="compact"
					variant="outlined"
					hide-details
					persistent-placeholder
					:spellcheck="true"
				></v-text-field>
				<div class="column-format gap-3" v-if="allowFullCoverageCampaigns">
					<v-select
						v-model="campaign.provisioningType"
						density="compact"
						variant="outlined"
						hide-details
						persistent-placeholder
						label="Provisioning type"
						:items="provisionOptions"
						item-value="value"
						item-title="label"
					>
					</v-select>
					<v-select
						v-model="campaign.fullCoverageNumberCount"
						density="compact"
						variant="outlined"
						hide-details
						persistent-placeholder
						label="Numbers per location"
						:items="numbersPerLocationOptions"
						v-if="campaign.provisioningType === 'FullCoverage'"
					>
					</v-select>
				</div>
				<v-autocomplete
					ref="businessIdentityId"
					:items="identities"
					label="CNAM identity"
					v-model="campaign.businessIdentityId"
					item-value="identityId"
					item-title="name"
					density="compact"
					variant="outlined"
					hide-details
					persistent-placeholder
				></v-autocomplete>
				<v-autocomplete
					v-if="isNotPinpointDialer"
					ref="dialerCampaignId"
					:items="dialerCampaigns"
					label="Associated dialer campaign"
					v-model="campaign.dialerCampaignId"
					item-value="id"
					item-title="name"
					density="compact"
					variant="outlined"
					hide-details
					persistent-placeholder
				></v-autocomplete>
				<div class="px-2" v-if="allowManagedCampaigns">
					<v-switch color="secondary" v-model="campaign.isManaged" hide-details density="compact" label="Enabled Managed Campaign - Import existing lists only (no uploads permitted)"></v-switch>
				</div>
			</div>
		</div>

		<div class="modal-footer row-format centered gap-2 pt-5">
			<v-btn min-width="100" class="super-action" @click.prevent="$emit('result')">Cancel</v-btn>
			<v-btn type="submit" min-width="100" elevation="0" class="primary-action ml-1" @click.prevent="createCampaign()">
				Create campaign
			</v-btn>
		</div>
	</div>
</template>

<script>
	import { defineComponent } from 'vue';
	import IdentityService from '@/modules/identities/IdentityService';
	import DialerService from '@/modules/dialers/DialerService';
	import CampaignService from '@/modules/campaigns/CampaignService';
	import PIcon from '@/components/PIcon';
	import WorkspaceService from '@/modules/workspaces/WorkspaceService';

	export default defineComponent({
		name: 'CreateCampaign',

		props: [],

		components: { PIcon },

		data: function () {
			return {
				campaignService: new CampaignService(),
				workspaceService: new WorkspaceService(),
				campaign: null,
				identities: [],
				dialerCampaigns: [],
				required: [(v) => !!v || 'Campaign name is required'],
				provisionOptions: [
					{ label: 'List based (recommended)', value: 'JustInTime' },
					{ label: 'Full coverage', value: 'FullCoverage' },
				],
				dialerProviderType: null,
			};
		},

		mounted() {
			this.getBusinessIdentities();
			this.getDialerCampaigns();
			this.getDialerProviderType();
			this.initCampaign();
		},

		beforeUnmount() {},

		methods: {
			createCampaign: function () {
				if (!this.campaign.name) {
					this.$store.commit('error', 'Campaign name is required');
					this.$refs.name.focus();
					return false;
				} else if (!this.campaign.businessIdentityId) {
					this.$store.commit('error', 'CNAM identity is required');
					this.$refs.businessIdentityId.focus();
					return false;
				} else if (!this.campaign.dialerCampaignId && this.isNotPinpointDialer) {
					this.$store.commit('error', 'Associated dialer mapping is required');
					this.$refs.dialerCampaignId.focus();
					return false;
				}

				if(!this.allowManagedCampaigns){
					this.campaign.isManaged = false;
				}

				this.$store.commit('startLoading');
				this.campaignService
					.createCampaign(this.workspaceId, this.campaign)
					.then((res) => {
						this.$emit('result', res.data);
					})
					.finally(() => this.$store.commit('stopLoading'));
			},

			initCampaign() {
				this.campaign = {
					id: null,
					name: null,
					businessIdentityId: null,
					dialerCampaignId: null,
					provisioningType: 'JustInTime',
					fullCoverageNumberCount: 1,
					isManaged: false,
				};
			},

			async getBusinessIdentities() {
				try {
					let result = await new IdentityService().getIdentities(this.workspaceId);
					this.identities.replace(result.data);
					this.identities.sort((a, b) => a.name.localeCompare(b.name));
				} catch (err) {
					this.$store.commit('error', err.response.data.message);
				}
			},

			async getDialerCampaigns() {
				try {
					let result = await new DialerService().getCampaigns(this.workspaceId);
					this.dialerCampaigns.replace(result.data);
					this.dialerCampaigns.sort((a, b) => a.name.localeCompare(b.name));
				} catch (err) {
					this.$store.commit('error', err.response.data.message);
				}
			},

			async getDialerProviderType(){
				let result = await this.workspaceService.getWorkspace(this.workspaceId);
				this.dialerProviderType = result.data.dialerProviderType;
			},
		},

		computed: {
			allowManagedCampaigns: function(){
				return this.workspace.dialerProviderType === 'RING';
			},

			workspaceId: function () {
				return this.$store.state.selectedWorkspace;
			},

			allowFullCoverageCampaigns: function () {
				return this.workspace.allowFullCoverageCampaigns;
			},

			numbersPerLocationOptions: function () {
				let size = this.workspace.maxNumbersPerFullCoverageCampaign;
				let result = [];
				for (let i = 1; i <= size; i++) {
					result.push(i);
				}
				return result;
			},

			workspace: function () {
				return this.$store.getters.getSelectedWorkspace;
			},

			isNotPinpointDialer: function(){
				return this.dialerProviderType !== 'CONNECT'
			},
		},
	});
</script>

<style scoped lang="scss"></style>
