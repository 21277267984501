<template>
	<v-container fluid class="">
		<v-row>
			<v-col cols="12">
				<module-header
					name="Bulk list uploader"
					add-label="Process files"
					@add-new="processFiles()"
					:workspace-selector="true"
					:disabled="!enabled || !files.length"
				>
				</module-header>
			</v-col>
		</v-row>
		<v-row>
			<v-col cols="12">
				<campaign-list-uploader class="my-4" @files-dropped="filesDropped" @error="filesError"></campaign-list-uploader>
				<v-autocomplete
					v-if="isFive9Dialer"
					class="mb-4"
					v-model="mode"
					label="Processing mode"
					:items="modeList"
					item-value="value"
					item-title="label"
					variant="outlined"
					density="compact"
					hide-details
				></v-autocomplete>
			</v-col>
		</v-row>
		<v-row dense class="text-left" style="border-bottom: 1px solid rgb(var(--v-theme-gray_30))">
			<v-col cols="3" class="font-weight-bold">File name</v-col>
			<v-col cols="1" class="font-weight-bold">Size</v-col>
			<v-col cols="8" class="font-weight-bold">Mapping</v-col>
		</v-row>
		<v-row
			dense
			v-for="(file, index) in files"
			:key="index"
			style="border-bottom: 1px solid rgb(var(--v-theme-gray_30))"
			class="text-left"
		>
			<v-col cols="3" class="text-truncate row-format align-center" v-tippy="{ content: file.name }">
				<div>
					{{ file.name }}
				</div>
			</v-col>
			<v-col cols="1" class="row-format align-center">
				<div>{{ Math.round((file.size / 1024 / 1024) * 100) / 100 }}/MB</div>
			</v-col>

			<template v-if="file.file">
				<v-col cols="8" class="row-format gap-2 align-center">
					<v-autocomplete
						style="width: 33%"
						density="compact"
						hide-details
						variant="outlined"
						:items="campaigns"
						v-model="file.campaignId"
						item-value="id"
						item-title="name"
						persistent-placeholder
						label="Campaign"
						@update:modelValue="() => setMappingTemplate(file)"
						clearable
					></v-autocomplete>
					<v-autocomplete
						style="width: 33%"
						density="compact"
						hide-details
						variant="outlined"
						:items="mappingTemplates"
						v-model="file.mappingTemplateId"
						item-value="id"
						item-title="name"
						persistent-placeholder
						label="Mapping template"
						clearable
					></v-autocomplete>
					<v-autocomplete
						v-if="!isRingOrPinpointDialer"
						style="width: 33%"
						density="compact"
						hide-details
						variant="outlined"
						:items="file.lists"
						v-model="file.dialerListId"
						item-value="id"
						item-title="name"
						persistent-placeholder
						label="Dialer list"
						clearable
					></v-autocomplete>
					<v-combobox
						v-if="isRingDialer"
						style="width: 33%"
						density="compact"
						hide-details
						variant="outlined"
						:items="file.lists"
						v-model="file.dialerListId"
						item-value="value"
						item-title="name"
						:return-object="false"
						persistent-placeholder
						label="Dialer list"
						clearable
					></v-combobox>
					<v-autocomplete
						v-if="isRingDialer"
						style="width: 33%"
						density="compact"
						hide-details
						variant="outlined"
						:items="dedupeOptions"
						v-model="selectedDedupe"
						item-value="value"
						item-title="label"
						persistent-placeholder
						label="De-dupe"
						clearable
					></v-autocomplete>
					<v-icon class="ml-auto material-symbols-outlined pointer" @click="removeFile(file)">delete</v-icon>
				</v-col>
			</template>
			<template v-else-if="file.campaignList">
				<v-col cols="4" class="text-left row-format align-center">
					<div class="font-14">
						Status: <span class="medium">{{ file.campaignList.status }}</span> <br />
						Started:
						<span class="medium" v-if="file.campaignList.processingStart">{{
							DateTime.fromISO(file.campaignList.processingStart).toLocaleString(DateTime.DATETIME_SHORT)
						}}</span
						><span v-else style="font-style: italic">--</span><br />
						Finish:
						<span class="medium" v-if="file.campaignList.processingFinish">{{
							DateTime.fromISO(file.campaignList.processingFinish).toLocaleString(DateTime.DATETIME_SHORT)
						}}</span
						><span v-else style="font-style: italic">--</span><br />
						Total time: <span class="medium">{{ formatSeconds(file.campaignList.processingDuration) }}</span>
					</div>
				</v-col>
				<v-col cols="4" class="row-format align-center font-14 text-right">
					<div style="width:100%; text-align: right">
						Loaded: <span class="medium">{{ file.campaignList.metrics.valid }}</span
						><br />
						Supplied: <span class="medium">{{ getTotal(file.campaignList.metrics) }}</span
						><br />
						Inactive: <span class="medium">{{ file.campaignList.metrics.inactive }}</span
						><br />
						Invalid: <span class="medium">{{ file.campaignList.metrics.invalid }}</span>
					</div>
				</v-col>
			</template>
			<template v-else>
				<v-col cols="8" class="row-format align-center">
					<div style="color: red; font-weight: 600">Error Processing File: {{ file.error }}</div>
				</v-col>
			</template>
		</v-row>
	</v-container>
</template>

<script>
	import { defineComponent } from 'vue';
	import CampaignListUploader from '@/modules/campaigns/CampaignListUploader';
	import ModuleHeader from '@/components/ModuleHeader';
	import CampaignService from '@/modules/campaigns/CampaignService';
	import MappingTemplateService from '@/modules/campaigns/MappingTemplateService';
	import { v4 as uuid } from 'uuid';
	import DialerService from '@/modules/dialers/DialerService';
	import CampaignListService from '@/modules/campaigns/CampaignListService';
	import { DateTime } from 'luxon';
	import WorkspaceService from '@/modules/workspaces/WorkspaceService';

	export default defineComponent({
		name: 'BulkUploader',

		props: [],

		components: { CampaignListUploader, ModuleHeader },

		data: function () {
			return {
				DateTime: DateTime,
				campaignService: new CampaignService(),
				mappingTemplateService: new MappingTemplateService(),
				dialerService: new DialerService(),
				campaignListService: new CampaignListService(),
				workspaceService: new WorkspaceService(),
				files: [],
				campaigns: [],
				dialerCampaigns: [],
				mappingTemplates: [],
				mode: 'REPLACE',
				modeList: [
					{ value: 'REPLACE', label: 'Replace all records' },
					{ value: 'APPEND', label: 'Append to list' },
				],
				dialerProviderType: null,
				dedupeOptions: [
					{value:'RING_REMOVE_ALL_EXISTING',label:'Yes, remove duplicates found in existing lists'},
					{value:'RING_REMOVE_FROM_LIST',label:'Yes, remove duplicates found in this list'},
					{value:'RING_RETAIN_ALL',label:'No, retain duplicates'}
				],
				selectedDedupe: null,
				campaignsReady: false,
				templatesReady: false,
				dialerReady: false,
			};
		},

		mounted() {
			this.getCampaigns();
			this.getMappingTemplates();
			this.getDialerCampaigns();
			this.getDialerProviderType();
			this.$store.state.eventBus.$on(`o-${this.orgId}.w-${this.workspaceId}`, this.handleEvents);
		},

		beforeUnmount() {
			this.$store.state.eventBus.$off(`o-${this.orgId}.w-${this.workspaceId}`, this.handleEvents);
		},

		methods: {
			handleEvents: function (event) {
				if (event.userMetadata === 'CampaignList') {
					let campaignList = event.message;
					let ix = this.files.findIndex((f) => f.campaignList && f.campaignList.id === campaignList.id);
					if (ix > -1) {
						let file = this.files[ix];
						file.campaignList = campaignList;
						this.files.splice(ix, 1, file);
					}
				}
			},

			getCampaigns() {
				this.campaignService
					.getCampaigns(this.workspaceId)
					.then((res) => {
						this.campaigns.replace(res.data.filter(c => c.status === 'ACTIVE' && !c.isManaged));
						this.campaigns.forEach((c) => {
							c.searchKey = c.name.replace(/[^a-z0-9]/gi, '').toLowerCase();
						});

						this.campaignsReady = true;
					})
					.catch((err) => {
						this.$store.commit('error', err.response.data.message);
					});
			},

			getDialerCampaigns() {
				this.dialerService
					.getCampaigns(this.workspaceId, true)
					.then((res) => {
						this.dialerCampaigns.replace(res.data);
						this.dialerCampaigns.sort((a, b) => a.name.localeCompare(b.name));
						this.dialerReady = true;
					})
					.catch((err) => {
						this.$store.commit('error', err.response.data.message);
					});
			},

			async getDialerProviderType(){
				let result = await this.workspaceService.getWorkspace(this.workspaceId);
				this.dialerProviderType = result.data.dialerProviderType;
			},

			getMappingTemplates() {
				this.mappingTemplateService
					.getMappingTemplates(this.workspaceId)
					.then((res) => {
						this.mappingTemplates.replace(res.data);
						this.templatesReady = true;
					})
					.catch((err) => {
						this.$store.commit('error', err.response.data.message);
					});
			},

			setMappingTemplate: function (file) {
				let campaign = this.combinedCampaigns.find((c) => c.id === file.campaignId);

				if (campaign) {
					file.lists.replace(campaign.lists);

					if (file.lists.length) {
						file.dialerListId = file.lists[0].id;
					}

					let mappingTemplate = this.mappingTemplates.find((m) => m.id === campaign.defaultMappingTemplate);

					if (mappingTemplate) {
						file.mappingTemplateId = mappingTemplate.id;
					} else {
						file.mappingTemplateId = null;
					}
				} else {
					file.mappingTemplateId = null;
					file.dialerListId = null;
					file.lists.splice(0);
				}
			},

			filesError: function (error) {
				this.$store.commit('error', error);
			},

			async filesDropped(files) {
				files.forEach((f) => {
					let campaignId = null;
					let mappingTemplateId = null;
					let dialerListId = null;
					let lists = [];

					let name = f.name.replace(/[^a-z0-9]/gi, '').toLowerCase();

					for (const campaign of this.combinedCampaigns) {
						if (name.includes(campaign.searchKey)) {
							campaignId = campaign.id;
							mappingTemplateId = campaign.defaultMappingTemplate;
							lists = [...campaign.lists];
							if (lists.length) {
								dialerListId = lists[0].id;
							}
							break;
						}
					}

					this.files.push({
						id: uuid(),
						file: f,
						name: f.name,
						size: f.size,
						mappingTemplateId: mappingTemplateId,
						campaignId: campaignId,
						dialerListId: dialerListId,
						lists: lists,
					});
				});
			},

			removeFile(file) {
				let ix = this.files.findIndex((f) => f.id === file.id);
				if (ix > -1) {
					this.files.splice(ix, 1);
				}
			},

			async processFiles () {
				if (!this.fixupAndVerifyFiles()) {
					return;
				}

				this.$store.commit('startLoading','Files uploading - please do not close this window');

				for (const _file_ of this.files) {
					let file = _file_;
					if(file.file) {
						try {
							console.log('Beginning processing of',file.file);
							await this.processFile(file);
							console.log('Finished processing file',file.file);
						}catch(err){
							this.$store.commit('error',err.response.data.message);
						}
					}
				}

				this.$store.commit('stopLoading')
			},

			async processFile (file) {
				return new Promise((resolve, reject) => {
					let request = {
						mode: this.mode,
						mappedCols: file.mappedCols,
						dialerListId: file.dialerListId,
						dedupe: this.selectedDedupe,
						firstRowHasHeaders: false,
						saveAsTemplate: false,
					};

					this.campaignListService
						.uploadAndProcessFile(this.workspaceId, file.campaignId, file.file, request)
						.then((res) => {
							file.campaignList = res.data;
							delete file.file;
							resolve(file);
						})
						.catch((err) => {
							file.error = err.response.data.message;
							delete file.file;
							reject(err);
						});
				});
			},

			fixupAndVerifyFiles: function () {
				for (const element of this.files) {
					let file = element;

					if(!file.file){
						continue;
					}

					if (!file.mappingTemplateId) {
						this.$store.commit('error', `Mapping template is required for ${file.file.name}`);
						return false;
					} else if (!file.campaignId) {
						this.$store.commit('error', `Campaign is required for ${file.file.name}`);
						return false;
					}
					if (!file.dialerListId && !this.isPinpointDialer) {
						this.$store.commit('error', `Dialer list is required for ${file.file.name}`);
						return false;
					}

					let mappingTemplate = this.mappingTemplates.find((m) => m.id === file.mappingTemplateId);
					file.mappedCols = mappingTemplate.mapping;
				}

				return true;
			},

			getTotal: function(metrics){
				return metrics.valid + metrics.invalid + metrics.dnc + metrics.inactive;
			},

			formatSeconds(seconds) {
				let hours = Math.floor(seconds / 3600);
				let minutes = Math.floor((seconds - hours * 3600) / 60);
				seconds = seconds - hours * 3600 - minutes * 60;

				if (hours < 10) {
					hours = '0' + hours;
				}
				if (minutes < 10) {
					minutes = '0' + minutes;
				}
				if (seconds < 10) {
					seconds = '0' + seconds;
				}
				return hours + ':' + minutes + ':' + seconds;
			},
		},

		watch: {
			enabled: {
				immediate: true,
				handler: function (val) {
					if(!this.$store.state.user) {
						return;
					}
					if (val) {
						this.$store.commit('stopLoading');
					} else {
						this.$store.commit('startLoading','Hang tight, we are collecting list information from your dialer.');
					}
				},
			},
		},

		computed: {
			workspaceId: function () {
				return this.$store.state.selectedWorkspace;
			},

			orgId: function () {
				return this.$store.state.user?.orgId;
			},

			enabled: function () {
				return this.dialerReady && this.campaignsReady && this.templatesReady;
			},

			combinedCampaigns: function () {
				let result = [...this.campaigns];

				result.forEach((c) => {
					let dialerCampaign = this.dialerCampaigns.find((d) => d.id === c.dialerCampaignId);
					if (dialerCampaign) {
						c.lists = [...dialerCampaign.lists];
					} else {
						c.lists = [];
					}
				});

				return result;
			},

			isRingOrPinpointDialer: function(){
				return this.isRingDialer || this.isPinpointDialer;
			},

			isRingDialer: function(){
				return this.dialerProviderType === 'RING'
			},

			isFive9Dialer: function(){
				return this.dialerProviderType === 'FIVE9'
			},

			isPinpointDialer: function(){
				return this.dialerProviderType === 'CONNECT'
			},
		},
	});
</script>

<style scoped lang="scss">
	#bulk-loader {
		.header-container {
			display: flex;
			.header-selector {
				max-width: 120px;
				&:hover {
					color: rgb(var(--v-theme-secondary));
				}
			}
			.unselect {
				&:hover {
					color: rgb(var(--v-theme-secondary));
				}
			}
		}
	}
</style>
