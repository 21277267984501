<template>
	<div class="mt-0" style="max-width: 95%">
		<v-container fluid class="ma-0 pa-0 text-left font-14">
			<v-row>
				<v-col cols="12" class="column-format gap-2">
					<v-checkbox v-model="features.duplicatePrevention" hide-details density="compact" label="Reject duplicate leads"></v-checkbox>
					<v-checkbox v-model="features.knownLitigatorScrubEnabled" hide-details density="compact" label="Watchtower Guard - Known litigator scrubbing"></v-checkbox>
					<v-checkbox v-model="features.dncScrubEnabled" hide-details density="compact" label="Watchtower Guard - National DNC scrubbing"></v-checkbox>
					<v-select v-if="features.dncScrubEnabled" v-model="features.ftcSanId" :items="computedSanList" item-value="id" item-title="label" hide-details density="compact" variant="outlined" placeholder="FTC SAN">
					</v-select>
					<v-checkbox v-model="features.veriskCheckEnabled" hide-details density="compact" label="Enable Watchtower Scan - Verisk Jornaya LeadiD verification"></v-checkbox>
					<v-btn class="primary-action" v-if="features.veriskCheckEnabled">This feature is not active on your account.  Click here to request activation.</v-btn>
					<v-checkbox v-model="features.activeProspectCheckEnabled" hide-details density="compact" label="Enable Watchtower Scan - ActiveProspect TrustedForm verification"></v-checkbox>
					<v-btn class="primary-action" v-if="features.activeProspectCheckEnabled">This feature is not active on your account.  Click here to request activation.</v-btn>
					<v-btn elevation="0" style="min-height: 40px" color="secondary" @click="save()">Save</v-btn>
				</v-col>
			</v-row>
		</v-container>
	</div>
</template>

<script>
	import { defineComponent } from 'vue';
	import FtcSanService from "@/modules/watchTower/FtcSanService";

	export default defineComponent({
		name: 'CampaignWatchtower',

		props: ['workspaceId', 'campaign'],

		components: {},

		data: function () {
			return {
				features:  { ...this.campaign.features },
				ftcSanService: new FtcSanService(),
				ftcSans: [],
			};
		},

		mounted() {
			this.getFtcSanList();
		},

		beforeUnmount() {},

		methods: {
			getFtcSanList: function(){
				this.ftcSanService.getFtcSanList(this.workspaceId).then((res) => {
					this.ftcSans.replace(res.data);
				})
			},

			save: function(){
				this.$emit('save', {
					features: this.features
				});
			},
		},

		computed: {
			computedSanList: function(){
				let result = [];
				this.ftcSans.forEach(f => {
					result.push({
						id: f.id,
						label: f.ftcSan + ': ' + f.ftcOrgName
					})
				});
				return result;
			}
		},
	});
</script>

<style scoped lang="scss"></style>
